import React, { useState, useEffect } from "react"
import Layout from '../../components/Layout'
import logo from '../../images/movember-logo.png';
import royalty from '../../images/movember-royalty.png';
import './styles.scss';

const Movember = () => {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-11-21`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
    <Layout className="movember">
      <img
        alt="Movember logo"
        src={logo}
        className="movember-logo"
      />
      <h1>Movember fundraiser</h1>
      <div className="movember-timer">
        <div>Next Drop</div>
        <div className="movember-countdown">
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
      </div>
      <section>
        <p>100% of the profit will go to <strong><a href="https://ca.movember.com/">Movember Canada</a></strong>. A registered charity number 84821 5604 RR0001.</p>
        <p>Your contribution will fund medical research 🔬, groundbreaking tests and trials 🧪, life-changing men’s health programs 💪🏼, and innovative treatments 🪄. </p>
        <p>You will be doing a whole lot of good for a whole lot of men.</p>
      </section>

      <section>
        <h2>Ok but, why doing this?</h2>
        <p>Why not? I'm combining technology and knowledge to come up with "stuff" that will improve the world we live in. And it's fun :D</p>
      </section>
      <section>
        <h2>How can I contribute?</h2>
        <p>Wellll this is the fun part I was talking about. You have to buy NFTs on <a href="https://opensea.io/collection/movember">OpenSea</a> .</p>
        <p>Here's a good tutorial that explain how to do it.</p>
      </section>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/RBw6YG3JSlU?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
<br />
<br />
<br />
      <section>
        <h2>NFT... But what is it?</h2>
        <p>NFT stands for Non-fungible token. </p>
        <p>“Non-fungible” more or less means that it’s unique and can’t be replaced with something else. </p>
        <p>Imagine a painting: there is only one physical object. </p>
        <p>Digital “things” can’t have the same kind of uniqueness, because every version of a jpg is the same, there is no “original”. </p>
        <p>So NFT is a technology to create a kind of certificate of uniqueness that is tied to any object, image, sound or anything really. </p>
        <p>It’s like a notarized ownership certificate for whatever you want. </p>
      </section>
      <section>
        <h2>Sell and secondary sales royalties</h2>
        <p>The first time an NFT will be sold, we will send 100% of the profit to the Movember fundation.</p>
        <p>And then, we will earn another 10% on each subsequent sale. Amazing right?</p>
        <p>In other words, those NFT can generate donation for the foundation for many years to come!!!</p>
      </section>




      <img
        alt="Royalty explanation"
        src={royalty}
        className="movember-royalty"
      />
      <section>
        <h2>DISCLAIMER</h2>
        <p>We chose OpenSea & MetaMask for their ease of use & exhaustive Help Center. If you have any questions, please visit either help desk below to answer any questions.</p>
        <h2>HOW TO SET-UP AND/OR LOAD YOUR WALLET?</h2>
        <p>Please visit the <a href="https://metamask.io/faqs.html" target="_blank">MetaMask Help Center</a>.</p>
        <h2>HOW TO BUY AND SELL FREEDOM COLUMN NFTS?</h2>
        <p>Please visit the <a href="https://support.opensea.io/hc/en-us" target="_blank">OpenSea Help Center</a>.</p>
        <p><small>NOTE: There is one extra step in buying JPixel because it is minted on Polygon to avoid as much gas fee as possible. If you get stuck, <a href="https://support.opensea.io/hc/en-us/articles/1500012889322-How-do-I-purchase-NFTs-on-Polygon-" target="_blank">learn more here</a>.</small></p>
      </section>



        

        

        
      </Layout>
  )
}

export default Movember
